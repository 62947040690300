<template>
  <div class="w-100 d-flex justify-content-center my-4">
    <div class="mb-0 landing w-100">
      <div class="card">
        <div class="card-header"><strong>About Rob</strong></div>
        <div class="card-body">
          <div class="author-image">
            <img src="/assets/images/author/rob.jpg" alt="Image" class="rounded-circle" />
          </div>
          <p>
            <i>
              <a href="https://www.linkedin.com/in/robastudillo/">
                Rob
              </a>
              is the CEO of eFlexervices. For more than 10 years,
              he has been building powerful business partnerships
              with US companies by building high-performing
              offshore teams that help optimize operations and
              build cost saving solutions to enable growth, scale,
              and optimization
            </i>
          </p>
          <div class="mt-2 mb-2 d-flex justify-content-center w-100 ">

            <router-link to="/aqqire-success/calendly"
              class="text-truncate font-bold px-4 p-3 mt-5 font-weight-bold callbutton rounded-lg text-center">
              <a>
                Book a Call with eFlex's CEO
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {

    }
  }
}
</script>

<style scoped>
.callbutton {
  max-width: 60%;
  width: 100%;
  font-size: 1.2em;
  background-color: #1abc9c;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.landing {
  margin: 0 auto;
  max-width: 50%;
}

@media screen and (max-width: 1280px) {
  .callbutton {
    max-width: 100%;
    font-size: 0.8rem;
  }

  .landing {
    max-width: 100%;
  }
}

@media screen and (min-width: 750px) and (max-width: 1280px) {
  .callbutton {
    max-width: 60%;
    font-size: 1rem;
  }

  .landing {
    max-width: 80%;
  }
}

</style>
