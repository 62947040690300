<template>
  <div class="about">
    <!-- Page Title
	============================================= -->
    <section id="page-title" class="page-title-parallax include-header" style="
        padding: 250px 0;
        background-image: url('/assets/images/landing/customersuccess.jpg');
        background-size: cover;
        background-position: center center;
      " data-bottom-top="background-position:0px 400px;" data-top-bottom="background-position:0px -500px;">
      <div class="container clearfix">
        <h1>Driving Engagement and Operational Excellence:</h1>
      </div>
    </section>
    <!-- #page-title end -->

    <!-- Content
	============================================= -->
    <section id="content">
      <div class="content-wrap">
        <div class="container clearfix">
          <div class="row col-mb-50 mb-0">
            <div class="col-lg-6" style="padding-left: 4em">
              <div class="heading-block fancy-title border-bottom-0 title-bottom-border">
                <h4>
                  HOW EFLEXERVICES BOOSTED AQQIRE'S MARKET PRESENCE AND BUSINESS
                  GROWTH
                </h4>
              </div>
              <p>
                <i>
                  Explore the transformative journey of AQQIRE, a
                  forward-thinking real estate platform, as they tapped into
                  eFlexervices' specialized marketing and sales operations
                  support. This story highlights how strategic operational
                  efficiencies, executed by eFlexervices, propelled AQQIRE's
                  engagement levels and expanded their business footprint.
                </i>
              </p>
            </div>

            <div class="col-lg-6" style="padding-left: 4em">
              <img src="/assets/images/clients/aqqire.png" alt="Aqqire Logo" />
            </div>
          </div>
        </div>
        <!-- Affinity Success START -->
        <div class="container clearfix">
          <div class="clear"></div>
          <div class="heading-block">
            <h4>ABOUT THE COLLABORATION</h4>
            <p>
              Join AQQIRE on their path to navigating the competitive real
              estate market with eFlexervices by their side. This partnership
              underscores the significance of redefining operational workflows
              and harnessing expert support to facilitate scalable growth and
              market penetration.
            </p>
            <h4>THE CHALLENGE FOR AQQIRE</h4>
            <p>
              AQQIRE, like many organizations, faces the challenge of maximizing
              impact with limited resources. With a pressing need to expand both
              their audience and content swiftly, AQQIRE had to focus their
              limited resources on two channels: email and cold calls.
            </p>
            <h4>THE STRATEGIC APPROACH BY EFLEXERVICES</h4>
            <p>
              Numerous competitors lacked substantial property listings or a
              sizable audience, and AQQIRE recognized the imperative to gain one
              or the other.
            </p>
            <p>
              Responding to AQQIRE's needs, eFlexervices recruited and deployed
              a skilled team of email marketers, lead gen specialists, and
              customer success reps to seek listings and users. Through
              meticulous process optimization, technology integration, and the
              application of analytics,
              <strong>
                eFlexervices bolstered AQQIRE's user base by ten fold in the
                first year of their partnership
              </strong>
              . Their approach included crafting and managing targeted email
              campaigns that not only resonated with the existing audience, but
              attracted new members, too.
            </p>
            <h4>RESULTING SUCCESS</h4>
            <p>
              The partnership between AQQIRE and eFlexervices led to notable
              achievements, including increased user engagement, an expanded
              user base, and added revenue streams. The bespoke email campaigns
              not only boosted AQQIRE's visibility but also streamlined the
              engagement process, leading to AQQIRE becoming a household name in
              the commercial real estate industry. This strategic operational
              support allowed AQQIRE to concentrate on its core mission,
              resulting in substantial user growth and revenue increases.
            </p>
            <h4>EFLEXERVICES AT THE HELM</h4>
            <p>
              eFlexervices' role transcended traditional marketing and sales
              activities, focusing on operational efficiencies and strategies.
              Their adept handling of the marketing technology stack and
              workflow optimization was key to operationalizing AQQIRE's growth
              strategy. The partnership enabled AQQIRE to deliver consistent,
              impactful messages to its target audience, laying the groundwork
              for continued success.
            </p>
          </div>
        </div>

        <section class="p-2 container">
          <Calendly />
        </section>
        <!-- AQQIRE Success END -->
        <!-- <section>
          <div class="content-wrap">
            <div class="container clearfix">
              <div class="row col-mb-80 landing">
                <div class="postcontent col-lg-9">
                  <div class="single-post mb-0">
                    <div class="card">
                      <div class="card-header"><strong>About Rob</strong></div>
                      <div class="card-body">
                        <div class="author-image">
                          <img
                            src="/assets/images/author/rob.jpg"
                            alt="Image"
                            class="rounded-circle" />
                        </div>
                        <p>
                          <i>
                            <a href="https://www.linkedin.com/in/robastudillo/">
                              Rob
                            </a>
                            is the CEO of eFlexervices. For more than 10 years,
                            he has been building powerful business partnerships
                            with US companies by building high-performing
                            offshore teams that help optimize operations and
                            build cost saving solutions to enable growth, scale,
                            and optimization
                          </i>
                        </p>
                        <div class="callbuttondiv">
                          <router-link to="/aqqire-success/calendly">
                            <a class="callbutton button-large nott ls0 center">
                              Book A Call with eFlex's CEO, Rob
                            </a>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->

        <!-- Client Section -->
        <div class="container clearfix">
          <div class="clear"></div>

          <div class="heading-block center">
            <h4>Our Clients</h4>
          </div>

          <ul class="clients-grid grid-2 grid-sm-4 grid-md-3 mb-0">
            <li class="grid-item">
              <img src="/assets/images/clients/aqqire.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/manscaped.jpeg" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/cvent.jpeg" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/iscribe.jpeg" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/copper.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/brightwheel.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/upcity.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/uservoice.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/affinity.jpg" alt="Clients" />
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>

<script>
import Calendly from '../components/Calendly.vue'
export default {
  data() {
    return {}

  },
  mounted() {
    window.scrollTo(0, 0)
  },
  components: {
    Calendly
  }
}
</script>

<style scoped>
#page-title {
  padding-top: 110px;
}

/* 
  .callbutton {
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    margin-left: 20px;
    background-color: #1abc9c;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px 30px;
    font-size: 1.2rem;
    line-height: 40px;
    margin-top: 2em;
  }

  @media screen and (min-width: 769px) {
    .landing {
      margin-left: 23%;
    }
    .callbuttondiv {
      text-align: center;
    }
  }

  @media screen and (max-width: 480px) {
    .callbutton {
      display: inline-block;
      position: relative;
      cursor: pointer;
      outline: none;
      white-space: nowrap;
      background-color: #1abc9c;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      border: none;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 10px 20px;
      font-size: 1rem;
      line-height: 40px;
      margin-top: 2em;
    }
  } */
</style>
